import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import renderModule from '@components/module';
import generateId from '@helpers/generate-id';
import { Container } from '../pages';

const pageIdGenerator = generateId();

interface PageInterface {
  data: {
    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
      };
    };
    allPrismicPage: {
      edges: {
        node: {
          data: {
            page_title: {
              text: string;
            };
            meta_description?: string;
            meta_title: string;
            meta_image?: {
              url?: string;
            };
            body: [];
          };
        };
      }[];
    };
  };
}

const Page = ({ data }: PageInterface): JSX.Element | null => {
  if (!data) return null;

  const {
    prismicGeneralConfig: {
      data: { favicon },
    },
    allPrismicPage: { edges },
  } = data;
  const { body } = edges[0].node.data;

  return (
    <Layout>
      <SEO
        meta_image={edges[0].node?.data?.meta_image?.url}
        meta_description={edges[0].node.data.meta_description}
        meta_title={edges[0].node.data.meta_title}
        title={edges[0].node.data.page_title?.text || 'Home'}
        faviconHref={favicon?.url || ''}
      />
      <Container>
        {body.map(
          (module) =>
            module && (
              <React.Fragment key={pageIdGenerator.next().value}>
                {renderModule(module)}
              </React.Fragment>
            )
        )}
      </Container>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query AllPagesQuery($uid: String) {
    prismicGeneralConfig {
      ...GeneralConfigFragment
    }
    allPrismicPage(filter: { uid: { eq: $uid, ne: "home" } }) {
      edges {
        node {
          ...PageFragment
        }
      }
    }
  }
`;
